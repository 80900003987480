<template>
  <div v-if="viewFlag">
    <h2 class="content_title">{{ $t('msg.ONEX010P090.006') }}</h2> <!-- LEG VESSEL -->
    <table class="tbl_col">
      <colgroup>
        <col style="width:8%">
        <col style="width:7%">
        <col style="width:8%">

        <col style="width:8%">
        <col style="width:8%">
        <col style="width:7%">
        <col style="width:7%">
        <col style="width:8%">

        <col style="width:8%">
        <col style="width:7%">
        <col style="width:8%">
        <col style="width:8%">
        <col style="width:8%">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.007') }}</th> <!-- Status -->
          <th>{{ $t('msg.ONEX010P090.008') }}</th> <!-- Seq -->
          <th>{{ $t('msg.ONEX010P090.009') }}</th> <!-- Vessel -->
          <th>{{ $t('msg.ONEX010P090.010') }}</th> <!-- Voy No -->
          <th>{{ $t('msg.ONEX010P090.011') }}</th> <!-- POL -->
          <th>{{ $t('msg.ONEX010P090.012') }}</th> <!-- Terminal -->
          <th>{{ $t('msg.ONEX010P090.013') }}</th> <!-- ETD -->
          <th>{{ $t('msg.ONEX010P090.014') }}</th> <!-- Vessel -->
          <th>{{ $t('msg.ONEX010P090.015') }}</th> <!-- Voy No -->
          <th>{{ $t('msg.ONEX010P090.016') }}</th> <!-- POD -->
          <th>{{ $t('msg.ONEX010P090.017') }}</th> <!-- Terminal -->
          <th>{{ $t('msg.ONEX010P090.018') }}</th> <!-- ETA -->
          <th>{{ $t('msg.ONEX010P090.019') }}</th> <!-- Operator -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.statusNm }}</td>
          <td>{{ item.legSeq }}</td>
          <td>{{ item.vslCd }}</td>
          <td>{{ item.etVoyNo }}</td>
          <td>{{ item.polPortCd }}</td>
          <td>{{ item.polTrmlCd }}</td>
          <td>{{ $ekmtcCommon.changeDatePattern(item.polEtd, '.') }}</td>
          <td>{{ item.imVslCd }} </td>
          <td>{{ item.imVoyNo }}</td>
          <td>{{ item.podPortCd }}</td>
          <td>{{ item.podTrmlCd }}</td>
          <td>{{ $ekmtcCommon.changeDatePattern(item.podEta, '.') }}</td>
          <td>{{ item.tfcoCstNo }}</td>
        </tr>
      </tbody>
    </table>
    <br>
  </div>
</template>

<script>
import commons from '@/api/services/commons'

export default {
  name: 'ShippingLogBLDetailLegVesselPop',
  props: {
    mainData: Object
  },
  data () {
    return {
      viewFlag: '',
      items: [],
      statusNm: ''
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: function () {
    console.log('B/L Detail Leg Vessel Pop')
    this.init()
  },
  watch: {
    mainData () {
      this.init()
    }
  },
  methods: {
    async init () {
      //1.공통코드 초기화
      const codeData = await commons.getCommonsCodesCommon(['01182'])
      this.statusNm = codeData['01182']

      //2.메인데이터 초기화
      const legVessel = this.mainData.legVessel

      if (legVessel && legVessel.length > 0) {
        this.items = legVessel.map((item) => {
          //STATUS 셋팅
          const statusNm = this.statusNm.filter((status) => {
              return status.cd === item.updtCatCd
          })[0]
          item.statusNm = statusNm ? statusNm.cdNm : ''

          return item
        })
        this.viewFlag = true
      } else {
        this.viewFlag = false
      }
    }
  }
}
</script>
