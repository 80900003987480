var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.viewFlag
    ? _c("div", [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.006"))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(0),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.007")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.008")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.009")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.010")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.011")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.012")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.013")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.014")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.015")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.016")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.017")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.018")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.019")))]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.items, function (item, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(item.statusNm))]),
                _c("td", [_vm._v(_vm._s(item.legSeq))]),
                _c("td", [_vm._v(_vm._s(item.vslCd))]),
                _c("td", [_vm._v(_vm._s(item.etVoyNo))]),
                _c("td", [_vm._v(_vm._s(item.polPortCd))]),
                _c("td", [_vm._v(_vm._s(item.polTrmlCd))]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.$ekmtcCommon.changeDatePattern(item.polEtd, "."))
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(item.imVslCd) + " ")]),
                _c("td", [_vm._v(_vm._s(item.imVoyNo))]),
                _c("td", [_vm._v(_vm._s(item.podPortCd))]),
                _c("td", [_vm._v(_vm._s(item.podTrmlCd))]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.$ekmtcCommon.changeDatePattern(item.podEta, "."))
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(item.tfcoCstNo))]),
              ])
            }),
            0
          ),
        ]),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }